import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRect } from "react-use-rect";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../Styling/Home.css";

import DropDownArrow from "../photos/DownArrow.png";
import computerDark from "../photos/computerDark.svg";
import computerLight from "../photos/computerLight.svg";
import diamondDark from "../photos/diamondDark.svg";
import diamondLight from "../photos/diamondLight.svg";
import paperplaneDark from "../photos/paperplaneDark.svg";
import paperplaneLight from "../photos/paperplaneLight.svg";
import checkmark from "../photos/checkmark.svg";
import { useNavigate } from "react-router-dom";
import websitePictues from "../photos/websites-image.png";
import fiveStars from "../photos/FiveStar.jpg";
import chevronArrowLight from "../photos/lightChevronArrow.png";
import chevronArrowDark from "../photos/darkChevronArrow.png";
import khoi from "../photos/about_us_photos/Khoi.jpg";
import omar from "../photos/about_us_photos/Omar.jpg";

function useIntersectionObserver(options) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
}

const slidingWords = [
  ["Recruitment", "Programs"],
  ["Career", "Development"],
  ["Innovation", "Skills"],
  ["Industry", "Exposure"],
  ["Outreach", "Initiatives"],
  ["Workplace", "Readiness"],
  ["Intern", "Programs"],
  ["Tech", "Skills"],
];

const gradients = {
  Recruitment: "linear-gradient(71.15deg, #8157b7 13.7%, #bfa4f0 86.86%)",
  Innovation: "linear-gradient(71.15deg, #8157b7 13.7%, #bfa4f0 86.86%)",
  Outreach: "linear-gradient(71.15deg, #8157b7 13.7%, #bfa4f0 86.86%)",
  Intern: "linear-gradient(71.15deg, #8157b7 13.7%, #bfa4f0 86.86%)",
  Career: "linear-gradient(71.15deg, #4891f0 13.7%, #7fc1f7 86.86%)",
  Industry: "linear-gradient(71.15deg, #4891f0 13.7%, #7fc1f7 86.86%)",
  Workplace: "linear-gradient(71.15deg, #4891f0 13.7%, #7fc1f7 86.86%)",
  Tech: "linear-gradient(71.15deg, #4891f0 13.7%, #7fc1f7 86.86%)",
};

const SlideText = ({ source }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [rect, setRect] = useState(null);
  const [rectRef] = useRect(setRect);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex((index) =>
        index === source.length - 1 ? 0 : index + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [source]);

  return (
    <span className="slide-text-container" style={{ width: rect?.width }}>
      <span style={{ visibility: "hidden" }} ref={rectRef}>
        {source[currentItemIndex][0]} {source[currentItemIndex][1]}
      </span>
      {source.map((words, index) => (
        <span
          key={words.join("-")}
          className={`slide-text ${index === currentItemIndex ? "active sliding-text-container" : ""}`}
        >
          <span 
            style={{
              background: gradients[words[0]],
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {words[0]}
          </span>{" "}
          <span
            style={{
              background: gradients[words[0]],
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {words[1]}
          </span>
        </span>
      ))}
    </span>
  );
};

const Testimonial = ({ name, image, quote, index, inView }) => (
  <motion.div
    className="testimonial"
    initial={{ opacity: 0, y: 50 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
    transition={{ delay: 0.2 * index, duration: 0.8 }}
  >
    <div className="testimonial-content">
      <p className="testimonial-quote">"{quote}"</p>
      <div className="testimonial-author">
        <div className="testimonial-info">
          <h4 className="testimonial-name">{name}</h4>
        </div>
      </div>
      <img src={fiveStars} alt="Five Stars" className="testimonial-stars" />
    </div>
  </motion.div>
);

const TestimonialSection = ({ testimonials }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div ref={ref} className="testimonial-section">
      <h2 className="section-title">What People Are Saying</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            quote={testimonial.quote}
            name={testimonial.name}
            image={testimonial.image}
            index={index}
            inView={inView}
          />
        ))}
      </div>
    </div>
  );
};

const DropdownArrow = ({ isOpen, onClick }) => {
  const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return (
    <img
      src={DropDownArrow}
      alt="Dropdown Arrow"
      onClick={onClick}
      style={{
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
        cursor: "pointer",
        width: "7.5px",
        height: "5px",
        filter: isDarkTheme ? "invert(0)" : "invert(1)",
      }}
    />
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <div className="faq-question" onClick={toggleOpen}>
        <h2>{question}</h2>
        <DropdownArrow
          className="dropdown-arrow"
          style={{ fill: "white" }}
          isOpen={isOpen}
          onClick={toggleOpen}
        />
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const Home = () => {
  const themeDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [heroRef, isHeroVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  const [problemSolutionRef, isProblemSolutionVisible] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const [testimonialsSectionRef, isTestimonialsSectionVisible] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const [faqSectionRef, isFaqSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  const [benefitsRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Increased from 0.3 to 0.5
        duration: 0.8, // Added overall duration for the container
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 50, // Reduced from 100 to 50
        damping: 15, // Increased from 10 to 15
        duration: 1.2, // Increased from 0.5 to 1.2
      },
    },
  };

  const benefits = [
    {
      icon: themeDark ? computerLight : computerDark,
      title: "End-to-End Web Solutions",
      description:
        "Get your business online with modern, responsive websites created by tech-savvy students using the latest web technologies and design trends.",
    },
    {
      icon: themeDark? diamondLight : diamondDark,
      title: "Tailored Design",
      description:
        "Receive high-quality websites at budget-friendly rates, perfect for small businesses looking to establish their online presence without breaking the bank.",
    },
    {
      icon: themeDark ? paperplaneLight : paperplaneDark,
      title: "Innovative Design",
      description:
        "We create cutting-edge products that blend style and function, solving problems creatively to make your brand stand out.",
    },
  ];

  const testimonials = [
    {
      name: "Joaquin Altamirano",
      quote:
        "We were hesitant to revamp our outdated website, but OC Interns made the entire process smooth and stress-free. They listened to our needs, delivered on time, and exceeded our expectations with a modern, user-friendly site that perfectly represents our brand.",
      
    },
    {
      name: "Evelyn Hart",
      quote:
        "Choosing OC Interns for our website development was one of the best decisions we've made. Their expertise, creativity, and professionalism were evident from start to finish. Our new site has received fantastic feedback from customers, and we've seen a clear boost in engagement and conversions.",
      
    },
    {
      name: "Orson Blake",
      quote:
        "Partnering with OC Interns gave us more than just a website—it gave us a competitive edge. Their innovative approach and dedication to quality helped us stand out in our industry, and we couldn't be happier with the results.",
      
    },
  ];

  const faqs = [
    {
      question: "How does OC-Interns work?",
      answer:
        "OC-Interns connects businesses with student developers. Businesses post their web development projects, and our platform matches them with qualified student developers. The students then work on the projects under professional guidance, delivering high-quality websites at affordable rates.",
    },
    {
      question: "Are the student developers qualified?",
      answer:
        "Yes, all our student developers go through a rigorous vetting process. They are selected based on their skills, portfolio, and academic performance. Additionally, they work under the guidance of experienced mentors to ensure high-quality deliverables.",
    },
    {
      question: "How much does it cost to use OC-Interns?",
      answer:
        "The cost varies depending on the project scope and complexity. However, our rates are generally more affordable than traditional web development agencies. We provide custom quotes for each project to ensure fair pricing for both businesses and students.",
    },
    {
      question:
        "What kind of support do you offer during the development process?",
      answer:
        "We offer comprehensive support throughout the development process. This includes project management tools, regular check-ins, and access to mentors. If any issues arise, our support team is always available to help resolve them promptly.",
    },
    {
      question: "How long does a typical project take?",
      answer:
        "Project timelines can vary depending on the complexity and scope of the work. Simple websites might take a few weeks, while more complex projects could take several months. We work with you to establish realistic timelines and milestones for your specific project.",
    },
  ];

  const navigate = useNavigate();

  const downPage = (section) => {
    const currentDivs = document.getElementsByClassName(section);
    if (currentDivs.length > 0) {
      const currentDiv = currentDivs[0];
      const nextDiv = currentDiv.nextElementSibling;
      if (nextDiv) {
        nextDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const getStartedClicked = () => {
    navigate("/signup");
  };

  const contactUsClicked = () => {
    window.location.href = "mailto:ocinternscontact@gmail.com";
  };

  const location = useLocation();
  useEffect(() => {
    if(location.hash === "#home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        block: "start"
      })
    } else if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  return (
    <div id="home" className="home-container">
      <div className="hero-section">
        <div
          ref={heroRef}
          className={`section-content ${isHeroVisible ? "show" : ""}`}
        >
          <div className="text-content">
            <h1 className="headline">
              Helping you with
              <br />
              <SlideText id="slide-texts" source={slidingWords} />
            </h1>
            <p className="subtext">
              Professional websites created by innovative students.
            </p>
            <div className="button-container">
              <button className="gradient-button" onClick={getStartedClicked}>
                Get Started
              </button>
              <button className="gradient-button" onClick={contactUsClicked}>
                Contact Us
              </button>
            </div>
          </div>
        </div>
        <img
          src={websitePictues}
          alt="website-pictures-home"
          className="hero-image"
        />
        {themeDark ? (
          <img src={chevronArrowLight} alt="Chevron Arrow" className="chevron-arrow" onClick={() => downPage('hero-section')}/>
        ) : (
          <img src={chevronArrowDark} alt="Chevron Arrow" className="chevron-arrow" onClick={() => downPage('hero-section')}/>
        )}
      </div>

      <div className="problem-solution-section">
        <motion.div
          ref={problemSolutionRef}
          className={`section-content ${
            isProblemSolutionVisible ? "show" : ""
          }`}
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: isProblemSolutionVisible ? 1 : 0,
            y: isProblemSolutionVisible ? 0 : 50,
          }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            className="video-container"
            initial={{ opacity: 0, x: -50 }}
            animate={{
              opacity: isProblemSolutionVisible ? 1 : 0,
              x: isProblemSolutionVisible ? 0 : -50,
            }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            <div className="video-placeholder"></div>
          </motion.div>
          <motion.div
            className="text-content"
            initial={{ opacity: 0, x: 50 }}
            animate={{
              opacity: isProblemSolutionVisible ? 1 : 0,
              x: isProblemSolutionVisible ? 0 : 50,
            }}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            <motion.h2
              className="section-title"
              initial={{ opacity: 0, y: -20 }}
              animate={{
                opacity: isProblemSolutionVisible ? 1 : 0,
                y: isProblemSolutionVisible ? 0 : -20,
              }}
              transition={{ delay: 0.6, duration: 0.4 }}
            >
              Bridging the Gap in <br></br>Web Development
            </motion.h2>
            <motion.p
              className="main-point"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isProblemSolutionVisible ? 1 : 0,
                y: isProblemSolutionVisible ? 0 : 20,
              }}
              transition={{ delay: 0.8, duration: 0.4 }}
            >
              In today's digital age, having a professional website is crucial
              for businesses and organizations. However, many struggle to find
              affordable, high-quality web development services that meet their
              unique needs.
            </motion.p>
            <motion.p
              className="solution-introduction"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isProblemSolutionVisible ? 1 : 0,
                y: isProblemSolutionVisible ? 0 : 20,
              }}
              transition={{ delay: 1, duration: 0.4 }}
            >
              OC-Interns bridges this gap by connecting innovative student
              developers with businesses and organizations in need of web
              development services. Our platform offers affordable,
              professional-quality websites while providing valuable experience
              to aspiring developers.
            </motion.p>
          </motion.div>
        </motion.div>
      </div>

      <div id="benefitSection" className="benefits-section">
        <motion.div
          ref={benefitsRef}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <h2 className="section-title">Our Benefits</h2>
          <motion.div className="benefits-container">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                className={`benefit ${index === 1 ? "center" : ""}`}
                variants={itemVariants}
              >
                <img
                  src={benefit.icon}
                  alt={`${benefit.title} Icon`}
                  className="benefit-icon"
                />
                <div className="benefit-content">
                  <h2 className="benefit-title">{benefit.title}</h2>
                  <p className="benefit-description">{benefit.description}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>

      <div className="testimonials-section">
        <div
          ref={testimonialsSectionRef}
          className={`section-content ${
            isTestimonialsSectionVisible ? "show" : ""
          }`}
        >
          <TestimonialSection testimonials={testimonials} />
        </div>
      </div>

      <div id="featureSection" className="features-section">
        <div>
          <h2 className="features-section-title">Features</h2>
          <div className="features-flex">
            <div className="parent-one-feature">
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Personalized Consultations</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Clear Milestones and Timelines</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Affordable Pricing</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Consistent Communication</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Client Feedback Integration</subtext>
              </div>
            </div>
            <div className="parent-two-feature">
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Fast Loading Speed</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Responsive Design</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>SEO Optimization</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Secure Hosting</subtext>
              </div>
              <div className="feature-item">
                <img src={checkmark} alt="Checkmark" className="check-icon" />
                <subtext>Post-Launch Support</subtext>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="faq" className="faq-section">
        <div
          ref={faqSectionRef}
          className={`section-content ${isFaqSectionVisible ? "show" : ""}`}
        >
          <h2 className="section-title">Frequently Asked Questions</h2>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
