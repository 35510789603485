import React, {useEffect, useState} from "react";
import { collection, getDocs} from "firebase/firestore"
import BusinessProfileCard from "./BusinessProfileCard";
import "../Styling/ExplorePage.css"
import Loading from "./Loading";
import {db} from "../firebaseInit"
import { query, where } from "firebase/firestore";

//TODO: Fix double rendering 
const ExplorePage = () => {
    const[businessData, setBusinessData] = useState([])
    const[loading, setLoading] = useState(true);   

    //only run once when the component mounts, not on every render
    useEffect(() => {
        const getBusinessData = async() => {
            const querySnapshotBusiness = await getDocs(query(collection(db, "BusinessProfile"), where("status", "==", "accepted")));
            //data is just each business profile doc with an id field which is the docs id
            const data = querySnapshotBusiness.docs.map((doc) => {
                let docData = doc.data();
                docData.id = doc.id;
                return docData;
            });
    
            setBusinessData(data);
            setLoading(false);   
        }
        getBusinessData();
    }, [])
    
    
    return (
        <div >
            <h1>Explore Page</h1>
            <h2> Choose a business right for you</h2>
            <div id="explore-page-body">
            {/* retunrs an array of busines profile cards */}
            {
                //having key prop allows react to idenify which elements changed
                /*include busID so that BusinessProfileCard can have a unique identifer
                which allows it to generate a unique route which allows you to pull up the
                correct data once you click the apply button. The apply page can use getParams()
                to identify the id then go from there*/

                loading ? (
                    <Loading/>
                ) : (
                        businessData.map((business, index) => (
                        <BusinessProfileCard 
                            key={index}
                            price={business.price}
                            busName={business.companyName} 
                            description={business.companyDescription}
                            location={business.companyLocation} 
                            busID={business.id}
                            businessType={business.businessType}
                        />
                        ))
                    )
            }
            </div>
        </div>
    )
}

export default ExplorePage;