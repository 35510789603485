import React from "react"
import "../Styling/Footer.css"

const Footer = () => {
    return (
<footer className="footer">
    <p>&copy; 2024 OCInterns. All rights reserved.</p>
    <ul className="footer-links">
        {/*<li><a href="#">Privacy Policy</a></li>*/}
        {/*<li><a href="#">Terms of Service</a></li>*/}
        <li><a href="mailto:ocinternscontact@gmail.com">Have questions? Contact Us</a></li>
    </ul>
</footer> )
}

export default Footer