//this is for businesses looking to see the apps they received
import React from "react";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { useState } from "react";
import "../Styling/Applications.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loading from "./Loading";
import { useEffect } from "react";
import { user, db, storage } from "../firebaseInit";
import { useRef } from "react";
import { updateDoc } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { doc } from "firebase/firestore";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

/*when testing this page, if get uid is null error, sign in first. 
If get map is null, make sure the business even has apps
*/

const MyApplications = () => {
    const[docData, setDocData] = useState([])
    const[loading, setLoading] = useState(true)
    const[showAlert, setShowAlert] = useState({show: false, row: 0})
    const[statusValue, setStatusValue] = useState([])
    const[selectedAccept, setSelectedAccept] = useState({disable: false, row: null})
    const timeoutRef = useRef(null);
    const [resumeFile, setResumeFile] = useState([])
    const [showPreview, setShowPreview] = useState({show: false, row: 0});
    const urlSet = new Set();

    useEffect(() => {
        const verifyAuthAndGetApps = async() => {
            if(user){
                const querySnapshotApp = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid)));
                const businessDocId = querySnapshotApp.docs[0].id;
                const querySnapshotApp2 = await getDocs(query(collection(db, "applications"), where("to", "==", businessDocId), orderBy("from", "asc")));
                
                //having statusValue done here is important because it must be in the same order as the docData array
                setDocData(querySnapshotApp2.docs.map((doc, index) => {
                    if(doc.data().status === "pending"){
                        setStatusValue(prev => [...prev, 1])
                    } else if (doc.data().status === "accepted"){
                        setStatusValue(prev => [...prev, 2])
                        setSelectedAccept({disable: true, row: index});
                    } else {
                        setStatusValue(prev => [...prev, 3])
                    }
                    return doc.data()
                }));
                
                // grab a array of resume files from firestore storage
                const listRef = ref(storage, 'Resumes/' + businessDocId);
                listAll(listRef)
                .then((result) => {
                    result.items.forEach((itemRef) => {
                        getDownloadURL(itemRef)
                        .then((url) => {
                            urlSet.add(url);
                            setResumeFile((Array.from(urlSet)).sort());
                        })
                        .catch((error) => {
                            
                        });
                    });
                })
                .catch((error) => {
                    
                });
                setLoading(false)
            }
        }

        verifyAuthAndGetApps();
    }, [])

    const handleChange = async(event, index) => {    
        setStatusValue(prevValue => {
            const copy = [...prevValue];
            copy[index] = event.target.value;
            return copy;
        })

        if(event.target.value === 2) {
            setSelectedAccept({disable: true, row: index});
        } else {
            setSelectedAccept({disable: false, row: index});
        }

        const querySnapshot = await getDocs(query(collection(db, "applications"), where('to', '==', docData[index].to),  where('from', '==', docData[index].from) ));
        //this should be one always
        if(querySnapshot.docs.length >= 1) {
            const docRef = doc(db, "applications", querySnapshot.docs[0].id);
            event.target.value === 1 ? await updateDoc(docRef, {
                status: "pending",
            }) : event.target.value === 2 ? await updateDoc(docRef, {
                status: "accepted",
            }) : await updateDoc(docRef, {
                status: "rejected",
            }) 
            setShowAlert({ show: true, row: index });
        } 
    };

    const handleCloseAlert = () => {
        setShowAlert({show: false, row: 0});
    }

    return (
        <div>
            <h1>Job Applications</h1>
            <div id="myapplications-page-body">
                {
                    loading ? (
                        <Loading/>
                    ) : docData.length !== 0 ? (
                        <div id="table-container">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Student Full Name</TableCell>
                                            <TableCell align="center">City, State</TableCell>
                                            
                                            <TableCell align="center">Language(s) Spoken: </TableCell>
                                            <TableCell align="center">School</TableCell>
                                            <TableCell align="center">Major</TableCell>
                                            <TableCell align="center">Graduation Year</TableCell>

                                            <TableCell align="center">Job Title</TableCell>
                                            <TableCell align="center">Technical Skills</TableCell>
                                            <TableCell align="center">Availible Hours</TableCell>
                                            <TableCell align="center">Resume</TableCell>
                                            <TableCell align="center">Accept/Reject</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {docData.map((doc, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{doc.fullName}</TableCell>
                                                    <TableCell align="center">{doc.cityState}</TableCell>
                                                    <TableCell align="center">{doc.languages}</TableCell>
                                                    <TableCell align="center">{doc.school}</TableCell>
                                                    <TableCell align="center">{doc.major}</TableCell>
                                                    <TableCell align="center">{doc.graduationYear}</TableCell>
                                                    <TableCell align="center">{doc.jobTitle}</TableCell>
                                                    <TableCell align="center">{doc.technicalSkills}</TableCell>
                                                    <TableCell align="center">{doc.availableHours}</TableCell>
                                                    <TableCell align="center" className="resume-column">
                                                        { showPreview.show && showPreview.row === index ? (
                                                            <VisibilityOffIcon className="resume-buttons preview-icon" onClick={() => setShowPreview({show: false, row: index})}/>
                                                        ) : (
                                                            <VisibilityIcon className="resume-buttons preview-icon" onClick={() => setShowPreview({show: true, row: index})}/>
                                                        )}
                                                            <FileDownloadIcon className="resume-buttons download-icon" onClick={() => window.open(resumeFile[index], '_blank')}/>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Status"
                                                                    value={statusValue[index]}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    disabled={selectedAccept.disable && selectedAccept.row !== index ? true : false}
                                                                >
                                                                    <MenuItem value={1}>Pending</MenuItem>
                                                                    <MenuItem value={2}>Accepted</MenuItem>
                                                                    <MenuItem value={3}>Rejected</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                {showAlert.show && showAlert.row === index && 
                                                    <TableRow>
                                                        <TableCell colSpan={11}>
                                                            <Alert icon={<CheckIcon fontSize="inherit" style={{alignSelf: "center", marginRight: "16px"}}/>}
                                                            severity="success"
                                                            onClose={handleCloseAlert}
                                                            >
                                                                <span className="update-message">
                                                                    <p>Successfully Updated.</p>{statusValue[index] === 2 ? <p className="Email-Reachout-Message">You will receive an email within 24 hours!</p>: null}
                                                                </span>
                                                            </Alert>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {showPreview.show && showPreview.row === index &&
                                                    <TableRow>
                                                        <TableCell colSpan={11}>
                                                            <iframe 
                                                                title="resumePreview" 
                                                                src={`${resumeFile[index]}#toolbar=0&view=Fith`} 
                                                                className="resume-preview-styling" 
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        //no doc data
                        <div id="no-apps-yet">
                            <h2>No Applications Yet!</h2>
                            <p id="text-left">It looks like no students have applied to your business yet. Check back later to see applications as they come in! If you need any help or have questions about the application process, feel free to contact us.</p>
                        </div>
                    )    
                }
            </div>
        </div>
    )
}

export default MyApplications