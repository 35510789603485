import { useState } from "react"
import { collection, addDoc } from 'firebase/firestore';
import { user, db } from "../firebaseInit";
import { query, where, limit, getDocs, updateDoc, doc } from "firebase/firestore";
import { Alert, Collapse } from '@mui/material';
import "../Styling/Alert.css";
import "../Styling/StudentProfile.css";

const StudentProfile = () => {
    //make state hooks for every peice of data
    const [studentName, setStudentName] = useState("")
    const [studentBio, setStudentBio] = useState("")
    const [studentLocation, setStudentLocation] = useState("")
    const [alertSuccess, SetOpenSuccess] = useState(false);
    const [alertFail, SetOpenFail] = useState(false); 

    const studentNameChange = (event) => {
        setStudentName(event.target.value)
    }
    const studentBioChange = (event) => {
        setStudentBio(event.target.value)
    }
    const studentLocationChange = (event) => {
        setStudentLocation(event.target.value)
    }

    const submit = async(event) => {
        event.preventDefault();
        const q = query(collection(db, "StudentProfile"), where("uID", "==", user.uid), limit(1));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.docs.length == 1) {
            await updateDoc(doc(db, "StudentProfile", querySnapshot.docs[0].id), {
                studentName: studentName,
                studentBio: studentBio,
                studentLocation: studentLocation
            });

            SetOpenSuccess(true);
        } else {
            //console.log("THIS SHOULD NOT BE HIT, means UID was not sent to firestore upon immediate authentication")
            await addDoc(collection(db, "StudentProfile"), {
                uID: user.uid,
                studentName: studentName,
                studentBio: studentBio,
                studentLocation: studentLocation
            });

            SetOpenFail(true);
        }
        
    }

    return (
        <div className="profile-box-one" >
            <h1 className="student-profile-h1">My Profile</h1>
            <form className="student-profile-update-form" onSubmit={submit}>
                <input type="text" className="student-profile-input-box" placeholder="Your Name" onChange={studentNameChange}></input>
                <textarea type="text" className="student-profile-input-box long-input" placeholder="Student Bio" onChange={studentBioChange}/>
                <input type="text" className="student-profile-input-box" placeholder="Student Location" onChange={studentLocationChange}/>
                <Collapse className="alert-container" in={alertSuccess}>
                    <Alert className="alert" variant="outlined" severity="success" onClose={() => {SetOpenSuccess(false)}}>Your information has been updated.</Alert>
                </Collapse>
                <Collapse className="alert-container" in={alertFail}>
                    <Alert className="alert" variant="outlined" severity="error" onClose={() => {SetOpenFail(false)}}>Your information has failed to update.</Alert>
                </Collapse>
                <button className="student-profile-update-button" type="submit">Update Info</button>
                
            </form>
        </div>
    )
}

export default StudentProfile