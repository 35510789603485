import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { reload, getAuth, onAuthStateChanged } from "firebase/auth";
import Loading from "../Loading";
import { db } from "../../firebaseInit";
/*cant rely on the user from firebaseInit because it may not be updated in time
onAuthStateChanged always hits by the way. You must subcsribe/unsubcribe from 
onAuthStateChanged to avoid the func from getting called when unmounted */

const CheckBusinessAuthenticated = ({ComponentWithAuth}) => {
    const[isBusiness, setIsBusiness] = useState(false);
    const[isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const auth = getAuth();

    const asyncWrapper = async(user) => {
        if(user) {
            const querySnapshot = await getDocs(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1));
            if(querySnapshot.docs.length !== 0) {
                setIsBusiness(true)
            } else {
                navigate('/signup')
            }
        } else {
            navigate('/signup')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        onAuthStateChanged(auth, asyncWrapper);
    }, [])

    if(isLoading) {
        return <Loading/>
    }
    if(isBusiness) {
        return <ComponentWithAuth />
    } 
}

export default CheckBusinessAuthenticated;