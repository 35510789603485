import "../Styling/BusinessProfileCard.css"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
// icon imports
import {LocationOn, StarBorder, Star, MoreVert, Business} from "@mui/icons-material";
// business types icons
import { Handshake, BusinessCenter, Storefront, RoomService, Factory, LocalGroceryStore, PrecisionManufacturing} from "@mui/icons-material"
import { MonetizationOn, MedicationLiquid, LocalLibrary, PeopleAlt, House, AirportShuttle, Construction, SportsEsports, Gavel } from "@mui/icons-material";
import { Icon, IconButton } from "@mui/material";

const BusinessProfileCard = ({price, busName, location, description, busID, businessType}) => {
    const [starActive, setStarActive] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [businessTypeIcon, setBusinessTypeIcon] = useState(null);
    const navigate = useNavigate();
    
    const start = () => {
        //no longer go through intermediary of navigate(`/apply/${busID}`);
        navigate(`/apply-form/${busID}`);
    }

    const toggleStar = () => {
        setStarActive(!starActive);
    }

    const toggleMore = () => {
        setShowMore(!showMore);
    }
    
    useEffect(() => {
        const handleBusinessType = () => {
            switch (businessType) {
                case "retail":
                    setBusinessTypeIcon(<Storefront className="icons"/>);
                    break;
                case "service":
                    setBusinessTypeIcon(<RoomService className="icons"/>);
                    break;
                case "manufacturing":
                    setBusinessTypeIcon(<Factory className="icons"/>);
                    break;
                case "wholesale":
                    setBusinessTypeIcon(<LocalGroceryStore className="icons"/>);
                    break;
                case "technology":
                    setBusinessTypeIcon(<PrecisionManufacturing className="icons"/>);
                    break;
                case "finance":
                    setBusinessTypeIcon(<MonetizationOn className="icons"/>);
                    break;
                case "health care":
                    setBusinessTypeIcon(<MedicationLiquid className="icons"/>);
                    break;
                case "education":
                    setBusinessTypeIcon(<LocalLibrary className="icons"/>);
                    break;
                case "hospitality":
                    setBusinessTypeIcon(<PeopleAlt className="icons"/>);
                    break;
                case "real estate":
                    setBusinessTypeIcon(<House className="icons"/>);
                    break;
                case "transportation":
                    setBusinessTypeIcon(<AirportShuttle className="icons"/>);
                    break;
                case "construction":
                    setBusinessTypeIcon(<Construction className="icons"/>);
                    break;
                case "entertainment":
                    setBusinessTypeIcon(<SportsEsports className="icons"/>);
                    break;
                case "legal":
                    setBusinessTypeIcon(<Gavel className="icons"/>);
                    break;
                case "nonprofit":
                    setBusinessTypeIcon(<Handshake className="icons"/>);
                    break;
                case "other":
                    setBusinessTypeIcon(<BusinessCenter className="icons"/>);
                    break;
                default:
                    
            }
        }

        handleBusinessType();
    }, []);
    
    return (
        <div id="profile-card-explore">
            <div className="action-bar">
                <div className="businessType">
                    {businessTypeIcon}
                </div>
                <div className="more">
                    <IconButton onClick={ toggleStar }>
                        {starActive ? (
                            <Star />
                        ) : (
                            <StarBorder />
                        )}
                    </IconButton>
                    <IconButton onClick={ toggleMore }>
                        <MoreVert />
                    </IconButton>
                </div>
            </div>
            <div id="text-container" onClick={start}>
                <h2 className="business-name-text"> {busName}</h2>
                <p className="business-description-text">{description}</p>
            </div>
            <div className="details">
                <div className="locationAndType">
                    <div className="detail">
                        <div className="detailIcons">
                            <LocationOn/>
                        </div>
                        <p className="detail-text"> {location}</p>
                    </div>
                    <div className="detail">
                        <div className="detailIcons">
                            {businessTypeIcon}
                        </div>
                        <p className="detail-text">{businessType}</p>
                    </div>
                </div>
                <div className="priceContainer">
                    <p className="payment-text">${price}</p>
                </div>
            </div>
        </div>
    )
}

export default BusinessProfileCard

