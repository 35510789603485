import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import Loading from "../Loading";
import { user, db } from "../../firebaseInit";
/*cant rely on the user from firebaseInit because it may not be updated in time
onAuthStateChanged always hits by the way. You must subcsribe/unsubcribe from 
onAuthStateChanged to avoid the func from getting called when unmounted */

const CheckAdminAuthenticated = ({ComponentWithAuth}) => {
    const[isAdmin, setIsAdmin] = useState(false);
    const[isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const asyncWrapper = async() => {
            if(user) {
                if(user.uid == "PnBA2xmGH5VrazR8JAF28XsGu2H2") {
                    setIsAdmin(true)
                } else {
                    navigate('/signup')
                }
            } else {
                navigate('/signup')
            }
            setIsLoading(false)
        }
        asyncWrapper()
    }, [navigate])

    if(isLoading) {
        return <Loading/>
    }
    if(isAdmin) {
        return <ComponentWithAuth />
    } else {
        return null;
    }
}

export default CheckAdminAuthenticated;