// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDe50b04Wr6zmvznUUE8zxouCcPabWPEk4",
  authDomain: "oc-interns.firebaseapp.com",
  projectId: "oc-interns",
  storageBucket: "oc-interns.appspot.com",
  messagingSenderId: "84330761151",
  appId: "1:84330761151:web:b665d7c185f5ae6bd3ef0a",
  measurementId: "G-VJ7Z6M7VWG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
let user = getAuth(app).currentUser;
//allows us to update auth when singin in on businessStartSignup/studnetStartSignup pages.
onAuthStateChanged(auth, (userNew) => {
  console.log("hit auth state changed")
  user = userNew;
});
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

//exporting db like this as opposed to doing getFirestore prevents reinitialization of db on every render
export {app, user, db, storage, auth};