import { useParams, useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore"
import { useState, useEffect } from "react";
import { db } from "../firebaseInit";
import "../Styling/Apply.css";

const Apply = () => {
    const [correctDoc, setCorrectDoc] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    /*later review: didn't even need to use 
    url parameters, I could have just done exporting/importing
    */
    const param = useParams();
    const navigate = useNavigate();
    const start = () => {
        navigate(`/apply-form/${param.busID}`);
    }

    useEffect(()=>{
        const getCompanyName = async (docID) => {
            const docRef = doc(db, "BusinessProfile", docID);
            const docSnap = await getDoc(docRef);
            const value = docSnap.data().companyName;
            setCompanyName(value);
        }

        getCompanyName(param.busID);
    }, [])
    
    useEffect(() => {
        const findCorrectDoc = async() => {
            setCorrectDoc(await getDoc(doc(db, "BusinessProfile", param.busID)))
        };
        findCorrectDoc();
    }, [])
    
    
    
    return (
        <div className="Business-Informations-Apply">
            {correctDoc && (
            <div id="Information-container">
                    <p className="payment-apply">${correctDoc.data().price}</p>
                    <h2 className="business-name-text-apply"> {companyName}</h2>
                    <p className="business-location-text-apply"> {correctDoc.data().companyLocation}</p>
                    <p className="business-description-text-apply">{correctDoc.data().companyDescription}</p>
            </div>
            )}

            <button id="start-apply" onClick={ start }>Apply</button>
        </div>
    )
}

export default Apply