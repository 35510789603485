import React, {Fragment} from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useRef } from "react";
import "../Styling/Login.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { query, getDocs, collection, where } from "firebase/firestore";
import { db } from "../firebaseInit";
import { Link } from "react-router-dom";

const Login = () => {
    const emailErrorElement = useRef();
    const passErrorElement = useRef();
    const emailRef = useRef();
    const passRef = useRef();   
    const[email, setEmail] = useState("");
    const[pass, setPass] = useState("");
    const[hidePass, setHidePass] = useState(true)
    const navigate = useNavigate();

    const errorBox = useRef(null);

    const handlePassChange = (event) => {
        setPass(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const togglePasswordVisibility = () => {
        setHidePass(!hidePass)
    }

    const signIn = (event) => {
        event.preventDefault();
        const auth = getAuth();
        emailErrorElement.current.style.display = "none";
        passErrorElement.current.style.display = "none";
        emailRef.current.style.border = "1px solid #ccc"
        passRef.current.style.border = "1px solid #ccc";

        signInWithEmailAndPassword(auth, email, pass)
        .then(async(userCredential) => {
            // Signed in 
            const user = userCredential.user;
            
            //if business, go to my applications
            //if student, go to explore
            const querySnapshotStudent = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", user.uid)));
            //is business
            if(querySnapshotStudent.empty) {
                navigate('/my-applications')
            } 
            //is student
            else {
                navigate('/explore-page')
            }
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            switch(errorCode) {
                case 'auth/invalid-email':
                    emailErrorElement.current.innerHTML = "Invalid email"
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
                case 'auth/weak-password':
                    passErrorElement.current.innerHTML = "Weak password"
                    passRef.current.style.border = "1px solid red"
                    emailRef.current.style.display = "block";
                    break
                case 'auth/email-already-in-use':
                    emailErrorElement.current.innerHTML = "Email already in use"
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
                case 'auth/invalid-credential':
                    passErrorElement.current.innerHTML = "Incorrect Email or Password"
                    passRef.current.style.border = "1px solid red"
                    passErrorElement.current.style.display = "block";
                    break
                default:
                    emailErrorElement.current.innerHTML = {errorMessage}
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
            }
        });
    }

    
        
    return (
 
            <div className="login-container">
                <h1 id="login-title-text">Login</h1>
                <p className="welcome-message">Enter your email below to log in.</p>
                <form onSubmit={signIn} className="login-form">
                    <div id="email-and-emailerror">
                        <input autoComplete="off" id="email" ref={emailRef} type="text" placeholder="Email"
                            onChange={handleEmailChange} required/>
                        <div ref={emailErrorElement} className="error-message"></div>
                    </div>
                    <div id="pass-and-passerror">
                        <input autoComplete="off" id="password" ref={passRef} type={hidePass ? "password" : "text"}
                            placeholder="Password" onChange={handlePassChange} required/>
                        {/*<span className="password-icon">*/}
                        {/*<FontAwesomeIcon*/}
                        {/*    icon={hidePass ? faEyeSlash : faEye}*/}
                        {/*    onClick={togglePasswordVisibility}/>*/}
                        {/*</span>*/}
                        <div ref={passErrorElement} className="error-message"></div>
                    </div>
                    <button id="login" type="submit">Submit</button>
                    
                    <p id="dont-have-account-text">Don't have an account? <Link to={'/signup'} id="no-account-link">Sign up here </Link> </p>
                    <p id="dont-have-account-link"><Link to={'/forgot-password'} id="forgot-password-text">Forgot password?</Link></p>
                </form>
            </div>
    
    )
}
export default Login;