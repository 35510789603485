import { useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc, getDocs, query, where  } from "firebase/firestore";
import { useState, useEffect } from "react";
import { user, db, storage } from "../firebaseInit";
import { Alert, AlertTitle, Collapse} from '@mui/material';
import { useRef } from "react";
import "../Styling/ApplyForm.css";
import "../Styling/Alert.css";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { ref, uploadBytes } from "firebase/storage";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
import CircularProgress from '@mui/material/CircularProgress';

// icon imports
import {LocationOn, StarBorder, Star, MoreVert, Business} from "@mui/icons-material";
// business types icons
import { Handshake, BusinessCenter, Storefront, RoomService, Factory, LocalGroceryStore, PrecisionManufacturing} from "@mui/icons-material"
import { MonetizationOn, MedicationLiquid, LocalLibrary, PeopleAlt, House, AirportShuttle, Construction, SportsEsports, Gavel } from "@mui/icons-material";
import { Icon, IconButton } from "@mui/material";

const ApplyForm = () => {
    const param = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showMissingField, setShowMissingField] = useState(false);
    const [resumeNotUploaded, setResumeNotUploaded] = useState(false);
    const [showError, setShowError] = useState(false);
    const [startedSubmit, setStartedSubmit] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);

    const timeoutRef = useRef(null);

    const [companyName, setCompanyName] = useState(null);
    const [data, setData] = useState(null);
    const [businessData, setBusinessData] = useState(null);

    const [resume, setResume] = useState(null);
    const auth = getAuth();

    //copied from BusinessProfileCard.js
    const [starActive, setStarActive] = useState(false);
    const [showMore, setShowMore] = useState(false);

    //copied from BusinessProfileCard.js 
    const [businessTypeIcon, setBusinessTypeIcon] = useState(null);
    useEffect(() => {
        const handleBusinessType = () => {
            
        }

        handleBusinessType();
    }, [businessData]);

    useEffect(() => {
        const getBusinessData = async() => {
            const docRef = doc(db, 'BusinessProfile', param.busID);
            const docSnap = await getDoc(docRef); 
            setBusinessData(docSnap.data());
            switch (docSnap.data().businessType) {
                case "retail":
                    setBusinessTypeIcon(<Storefront className="icons"/>);
                    break;
                case "service":
                    setBusinessTypeIcon(<RoomService className="icons"/>);
                    break;
                case "manufacturing":
                    setBusinessTypeIcon(<Factory className="icons"/>);
                    break;
                case "wholesale":
                    setBusinessTypeIcon(<LocalGroceryStore className="icons"/>);
                    break;
                case "technology":
                    setBusinessTypeIcon(<PrecisionManufacturing className="icons"/>);
                    break;
                case "finance":
                    setBusinessTypeIcon(<MonetizationOn className="icons"/>);
                    break;
                case "health care":
                    setBusinessTypeIcon(<MedicationLiquid className="icons"/>);
                    break;
                case "education":
                    setBusinessTypeIcon(<LocalLibrary className="icons"/>);
                    break;
                case "hospitality":
                    setBusinessTypeIcon(<PeopleAlt className="icons"/>);
                    break;
                case "real estate":
                    setBusinessTypeIcon(<House className="icons"/>);
                    break;
                case "transportation":
                    setBusinessTypeIcon(<AirportShuttle className="icons"/>);
                    break;
                case "construction":
                    setBusinessTypeIcon(<Construction className="icons"/>);
                    break;
                case "entertainment":
                    setBusinessTypeIcon(<SportsEsports className="icons"/>);
                    break;
                case "legal":
                    setBusinessTypeIcon(<Gavel className="icons"/>);
                    break;
                case "nonprofit":
                    setBusinessTypeIcon(<Handshake className="icons"/>);
                    break;
                case "other":
                    setBusinessTypeIcon(<BusinessCenter className="icons"/>);
                    break;
                default:
                    
            }
        }
        getBusinessData();
    }, [])

    useEffect(()=>{
        const getCompanyNameAndPrice = async (docID) => {
            const docRef = doc(db, "BusinessProfile", docID);
            const docSnap = await getDoc(docRef);
            const name = docSnap.data().companyName;
            setCompanyName(name);
            setData( (prevState) => ({
                ...prevState,
                price: docSnap.data().price
            }))
        }

        getCompanyNameAndPrice(param.busID);
    }, [])

    const initializeData = async() => {
        if(user){
            setData({
                // Step 1
                fullName: '',
                birthday: '',
                cityState: '',
                languages: '',
                // Step 2
                school: '',
                major: '',
                graduationYear: '',
                gpa: '',
                // Step 3
                jobTitle: '',
                technicalSkills: '',

                //needed info
                from: user.uid,
                to: param.busID,
                status: 'pending',
                //i dont belive we need price here
                price: ''
            })
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, initializeData);
    }, [])

    useEffect(() => {
        const backgroundElements = document.querySelectorAll('.title-container, #right-panel'); // Adjust the selector as needed
    
        if (showAlert || showSuccess || showMissingField || resumeNotUploaded || showError) {
          backgroundElements.forEach(element => {
            element.classList.add('blur-background');
          });
        } else {
          backgroundElements.forEach(element => {
            element.classList.remove('blur-background');
          });
        }
      }, [showAlert, showSuccess, showMissingField, resumeNotUploaded, showError]);

    /*don't need to wrap handleChange nor submitApplyForm in useEffect 
    (it wont be ran on every render) becasue it runs on button click. 
    If the funciton were called right beneath the definition, you would need to 
    wrap it in useEffect to prevent it from running on every render.*/
    const handleChange = (key, event) => {
        setData( (prevState) => ({
            ...prevState,
            [key]: event.target.value 
        }))
    }

    const submitApplyForm = async(event) => {
        setStartedSubmit(true);
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "applications"), where("from", "==", user.uid), where("to", "==", param.busID)));
            if(querySnapshot.empty) {
                if(resume){
                    // check if fields are empty
                    if(data.fullName === '' || data.dateOfBirth === '' || data.gender === '' 
                            || data.educationBackground === '' || data.major === '' || data.jobTitle === ''
                            || data.lang === '' || data.technicalSkill === '' || data.legalStat === ''){
                        
                        setShowMissingField(true);
                        setStartedSubmit(false)
                        timeoutRef.current = setTimeout(() => {
                            setShowMissingField(false);
                        }, 5000);
                        console.log("missing fields")
                    } else {
                        //can't have set then use right after
                        const resumeRef = (ref(storage, 'Resumes/' + param.busID + '/' + user.uid))
                        if(resumeRef){
                            uploadBytes(resumeRef, resume)
                            .then(async (snapshot) => {
                                // console.log('Uploaded Resume!', snapshot);
                                try{
                                    await addDoc(collection(db, "applications"), data);
                                } catch (error) {
                                    console.error("Error adding document: ", error);
                                }
                            
                                setShowSuccess(true);
                                timeoutRef.current = setTimeout(() => {
                                    setShowSuccess(false);
                                }, 5000);
                                
                                setDoneLoading(true)
                                setStartedSubmit(false)
                            })
                            .catch((error) => {
                                console.log("hit erorr 1")
                            });
                        } else {
                            console.log("hit resume not being uplodaed")
                        }
                    }
                } else {
                    setResumeNotUploaded(true);
                    timeoutRef.current = setTimeout(() => {
                        setResumeNotUploaded(false);
                    }, 5000);
                }
            } else {
                //this is hit if already saw an application with from and to filled out
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setShowAlert(true);
                timeoutRef.current = setTimeout(() => {
                    setShowAlert(false);
                }, 5000);
            }
        } else {
            setShowError(true);
            timeoutRef.current = setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
        
    }

    const handleResumeChange = (event) => {
        setResume(event.target.files[0]);
    }

    return (
        <div className="form-container">
            <div id="left-and-right-panels">
                <div className="title-container">
                    <h1 id="apply-title" className="title">Apply</h1>
                    {/*loading happens after click submit and while not done loading*/}
                    { (startedSubmit && !doneLoading) ? (
                        <CircularProgress className="alert-container"/>
                    ) : (
                        <div>
                            <Collapse className="alert-container-apply" in={resumeNotUploaded}>
                                <Alert className="alert" severity="error" varient="outlined">No Resume Found! Check If You Have Uploaded Your Resume!!</Alert>
                            </Collapse>
                            <Collapse className="alert-container-apply" in={showMissingField}>
                                <Alert className="alert" severity="error" varient="outlined">Please Fill Out All Fields!</Alert>
                            </Collapse>
                            <Collapse className="alert-container-apply" in={showAlert}>
                                <Alert className="alert" severity="warning" varient="outlined">You Already Applied To This Business!</Alert>
                            </Collapse>
                            <Collapse className="alert-container-apply" in={showError}>
                                <Alert className="alert" severity="error" varient="outlined">Something Went Wrong!!! Please Contact OC-Interns</Alert>
                            </Collapse>
                            <Collapse className="alert-container-apply" in={showSuccess}>
                                <Alert className="alert" severity="success" varient="outlined" >Application Submitted!</Alert>
                            </Collapse>
                        </div>
                    )
                    }
                    
                    
                    <div id="horizontal-stepper-container">
                        <HorizontalLinearStepper 
                            handleChange={handleChange} 
                            formData={data} 
                            handleResumeChange={handleResumeChange} 
                            resume={resume} 
                            submitApplyForm={submitApplyForm}
                        />
                    </div>
                </div>
                {resumeNotUploaded && (
                    <Collapse className="alert-container" in={resumeNotUploaded}>
                        <Alert className="alert applyAlert" severity="error" variant="filled" sx={{fontSize: '20px'}}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>No Resume Found!</AlertTitle>
                            Check If You Have Uploaded Your Resume!!
                        </Alert>
                    </Collapse>
                )}
                {showMissingField && (
                    <Collapse className="alert-container" in={showMissingField}>
                        <Alert className="alert applyAlert" severity="error" variant="filled" sx={{fontSize: '20px'}}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Missing Fields!</AlertTitle>
                            Please Fill Out All Input Fields!
                        </Alert>
                    </Collapse>
                )}
                {showAlert && (
                    <Collapse className="alert-container" in={showAlert}>
                        <Alert className="alert applyAlert" severity="warning" variant="filled" sx={{fontSize: '20px'}}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Already Applied!</AlertTitle>
                            You Have Already Applied To This Business!
                        </Alert>
                    </Collapse>
                )}
                {showError && (
                    <Collapse className="alert-container" in={showError}>
                        <Alert className="alert applyAlert" severity="error" variant="filled" sx={{fontSize: '20px'}}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Error Occurred!</AlertTitle>
                            Something Went Wrong!!! Please Contact OC-Interns If This Issue Persists!
                        </Alert>
                    </Collapse>
                )}
                {showSuccess && (
                    <Collapse className="alert-container" in={showSuccess}>
                        <Alert className="alert applyAlert" severity="success" variant="filled" sx={{fontSize: '20px'}}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Application Submitted!</AlertTitle>
                            Application Was Successfully Submitted!
                        </Alert>
                    </Collapse>
                )}
                { businessData ? (
                    //copied from BusinessProfileCard.js explore card
                        <div id="right-panel">

                            <div className="businessType">
                                {businessTypeIcon}
                            </div>

                            <div className="name-and-desc make-grow">
                                <h1> 
                                    {businessData.companyName}
                                </h1>

                                <p id="company-description-apply-form-page">
                                {businessData.companyDescription}
                                </p>
                            </div>
                            <div id="shove-to-bottom">
                                <div className="make-grow">
                                    <div id="location-icon-text">
                                    <span className="right-margin"><LocationOn/></span>
                                    <span>{businessData.companyLocation}</span>
                                    </div>
                                </div>

                                <div className="turn-row make-grow">
                                   <div id="business-type-icon-text">
                                        <p className="right-margin">{businessTypeIcon}</p>
                                        <p >{businessData.businessType}</p>
                                    </div>
                                </div>
                            </div>
                              
                        </div>
                    ) : (
                        <h1>Loading...</h1>
                    )
                }
            </div>

        </div>
    )
}

export default ApplyForm
