//TODO: when put into production, turn on security (change lalow read, write: if false)
import { useState, useEffect } from 'react';
import { collection, addDoc, query, where, getDocs, doc} from 'firebase/firestore';
import { user, db } from "../firebaseInit";
import { updateDoc } from 'firebase/firestore';
import { limit } from 'firebase/firestore';
import { Alert, Collapse, Tooltip } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import "../Styling/Alert.css";
import '../Styling/BusinessProfile.css';
import BackHandIcon from '@mui/icons-material/BackHand';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TvOffIcon from '@mui/icons-material/TvOff';
import CastIcon from '@mui/icons-material/Cast';
import InfoIcon from '@mui/icons-material/Info';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';


const BusinessProfile = () => {
    //make state hooks for every peice of data
    const [companyName, setCompanyName] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [companyLocation, setCompanyLocation] = useState("")
    const [price, setPrice] = useState("");
    const [companyNamePlaceholder, setCompanyNamePlaceholder] = useState(null)
    const [companyDescriptionPlaceholder, setCompanyDescriptionPlaceholder] = useState(null)
    const [companyLocationPlaceholder, setCompanyLocationPlaceholder] = useState(null)
    const [pricePlaceholder, setPricePlaceholder] = useState(null);
    const [alertSuccess, SetOpenSuccess] = useState(false);
    const [alertFail, SetOpenFail] = useState(false);
    const [alertWarning, SetOpenWarning] = useState(false);
    const [warningMessage, SetWarningMessage] = useState("");
    const [businessType, setBusinessType] = useState("");
    const businessTypes = [
        "retail",
        "service",
        "manufacturing",
        "wholesale",
        "technology",
        "finance",
        "health care",
        "education",
        "hospitality",
        "real estate",
        "transportation",
        "construction",
        "entertainment",
        "legal",
        "nonprofit",
        "other"
    ];
    const [iconForStatus, setIconForStatus] = useState("");
    const auth = getAuth();

    const companyNameChange = (event) => {
        setCompanyName(event.target.value)
    }
    const companyDescriptionChange = (event) => {
        setCompanyDescription(event.target.value)
    }
    const companyLocationChange = (event) => {
        setCompanyLocation(event.target.value)
    }
    const companyTypeChange = (event) => {
        if(event.target.value !== "other") {
            setBusinessType(event.target.value)
        } else {
            setBusinessType("selected other")
        }
    }
    const priceChange = (event) => {
        const formattedPrice = formatPriceWithCommas(event.target.value);
        setPrice(formattedPrice)
    }


    const formatPriceWithCommas = (value) => {
        const parts = value.split('.');
    
        // Format the whole number part with commas
        const wholeNumberPart = parts[0].replace(/[^\d]/g, '');
        const formattedWholeNumber = wholeNumberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
        // Limit the decimal part to two digits
        const decimalPart = parts[1] !== undefined ? '.' + parts[1].slice(0, 2) : '';
    
        return formattedWholeNumber + decimalPart;
    };

    //retrieve live, pending, or rejected status
    
    const retrieveStatus = async() => {
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));
            if(querySnapshot.docs.length != 0) {
                if(querySnapshot.docs[0].data().status == "pending") {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Pending</h2>
                            <Tooltip title="Profile currently under review by OC Interns!" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    )
                } else if (querySnapshot.docs[0].data().status == "accepted") {
                    setIconForStatus(
                            <div className="statusBlock">
                                <h2>Accepted</h2>
                                <Tooltip title="Profile accepted by OC Interns! Students can now see your profile" placement='right' arrow>
                                    <InfoIcon className="statusIcon"/>
                                </Tooltip>
                            </div>
                    )
                } else if(querySnapshot.docs[0].data().status == "rejected") {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Rejected</h2>
                            <Tooltip title="Profile rejected by OC Interns! Please contact OC-Interns for more information" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    ) 
                } else {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Not Live</h2>
                            <Tooltip title="Profile not live! Please update your profile to be reviewed by OC Interns" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    )
                }
            }
        }
    }

    const fetchData = async() => {
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));
            querySnapshot.forEach((doc) => {
                setCompanyDescriptionPlaceholder(doc.data().companyDescription);
                setCompanyNamePlaceholder(doc.data().companyName);
                setCompanyLocationPlaceholder(doc.data().companyLocation);
                setPricePlaceholder(doc.data().price);
            });
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, retrieveStatus);
        onAuthStateChanged(auth, fetchData);
    }, [])

    //send data to firestore/ async await due to
    /*must put the uID in firestore to know which student
    sent an application to which business*/
    const submit = async(event) => {
        event.preventDefault();
        const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));

        const data = {
            companyName: companyName,
            companyDescription: companyDescription,
            companyLocation: companyLocation,
            price: price,
            status: "pending",
            businessType: businessType
        }
        

        if(companyName == "" || companyLocation == "" || companyDescription == "" || price == "" || businessType == "" || businessType == "selected other"){
            let message = "Please fill out all fields (including your business type) to update/set your profile!";
            SetWarningMessage(message);
            SetOpenSuccess(false);
            SetOpenWarning(true);
            SetOpenFail(false);
        } else if(querySnapshot.docs.length == 1){
            await updateDoc(doc(db, "BusinessProfile", querySnapshot.docs[0].id), data);
            retrieveStatus();
            SetOpenSuccess(true);
            SetOpenWarning(false);
            SetOpenFail(false);
        } else {
            //"THIS SHOULD NOT BE HIT, means UID was not sent to firestore upon immediate authentication")
            await addDoc(collection(db, "BusinessProfile"), {
                uID: user.uid,
                companyName: companyName,
                companyDescription: companyDescription,
                companyLocation: companyLocation
            });

            SetOpenFail(true);
            SetOpenSuccess(false);
            SetOpenWarning(false);
        }
    }    

    return (
        <div className="business-profile-box">
            <h1 className="business-profile-h1">My Profile</h1>
            {
                <div>
                    <form onSubmit={submit}>
                        {iconForStatus}
                        <input type="text" className="input-field-box" value={companyName} placeholder= { companyNamePlaceholder ? "Company Name: " + companyNamePlaceholder : "Company Name" } onChange={companyNameChange}/>
                        <textarea type="text" className="input-field-box long-input" value={companyDescription} placeholder={ companyDescriptionPlaceholder ? "Company Description: " + companyDescriptionPlaceholder : 'Company Description' } onChange={companyDescriptionChange}/>
                        <input type="text" className="input-field-box" value={companyLocation} placeholder={ companyLocationPlaceholder ? "Company Location: " + companyLocationPlaceholder : 'Company Location' } onChange={companyLocationChange}/>
                        <div id="money-container">
                            <span id="money-symbol">$</span>
                            <input type='text' className='input-field-box' value={price} placeholder={ pricePlaceholder ? "Current Price: $" + pricePlaceholder : 'Agreed Price' } onChange={priceChange}/>
                        </div>
                        
                        
                        <Box sx={{ minWidth: 120, marginTop: '15px' }} className="business-type-dropdown-container">
                            <FormControl fullWidth>
                                <InputLabel id="businessTypeLabel">Business Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Business Type"
                                    value={businessType}
                                    onChange={companyTypeChange}
                                    sx={{
                                        //curves box border/colors same as others
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '12px',
                                        borderColor: '#ccc', // This is important to avoid text cutting
                                       
                                      },
                                     }}
                                >                       
                                    {businessTypes.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        
                        <Collapse className="alert-container" in={alertSuccess}>
                            <Alert className="alert" variant="outlined" severity="success" onClose={() => {SetOpenSuccess(false)}}>Your information is pending an update.</Alert>
                        </Collapse>
                        <Collapse className="alert-container" in={alertWarning}>
                            <Alert className="alert" variant="outlined" severity="warning" onClose={() => {SetOpenWarning(false)}}>{warningMessage}</Alert>
                        </Collapse>
                        <Collapse className="alert-container" in={alertFail}>
                            <Alert className="alert" variant="outlined" severity="error" onClose={() => {SetOpenFail(false)}}>Your information has failed to update.</Alert>
                        </Collapse>
                        {businessType === "selected other" && 
                            <input type="text" className="input-field-box" placeholder="Other Business Type" onChange={companyTypeChange} />
                        }
                        <button className="student-profile-button" type="submit">Update Info</button>
                    </form>
                </div>

            }
        </div>
    )
}

export default BusinessProfile
