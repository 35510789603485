import React, {useState, useRef } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseInit";
import "../Styling/Login.css"
import { Alert, Collapse } from '@mui/material';
import InputMask from 'react-input-mask';

const BusinessStartSignup = () => {
    const emailErrorElement = useRef();
    const passErrorElement = useRef();
    const emailRef = useRef();
    const passRef = useRef(); 
    const nameRef = useRef();
    const phoneNumberRef = useRef();
    const retypeErrorElement = useRef();
    const retypePassRef = useRef();

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [retypePass, setRetypePass] = useState("")
    const [alertInfo, SetOpenInfo] = useState(false);


    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }
    const handlePassChange = (event) => {
        setPass(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handlePhoneNumberChange = (event) => {    
        setPhoneNumber(event.target.value)
    }

    const handlePasswordRetype = (event) => {
        setRetypePass(event.target.value)
    }

    const signupSubmit = (event) => {
        event.preventDefault();
        const auth = getAuth();
        emailErrorElement.current.style.display = "none";
        passErrorElement.current.style.display = "none";
        retypeErrorElement.current.style.display = "none";
        emailRef.current.style.border = "1px solid #ccc"
        passRef.current.style.border = "1px solid #ccc";

        if(pass !== retypePass) {
            retypeErrorElement.current.innerHTML = "Passwords do not match"
            retypeErrorElement.current.style.display = "block";
            return;
        }
        
        createUserWithEmailAndPassword(auth, email, pass)
        .then(async(userCredential) => {
            const user = userCredential.user;
            user.name = name;

            // this still doesn't work
            const actionCodeSettings = {
                // change url to new domain name when deployed
                url: 'http://ocinterns.com/verification',
                handleCodeInApp: true
            };
            
            // send the email with verification.js link 
            // (make sure to change action link on firebase console when website deployed
            //  from localhost domain to the new domain name)
            await sendEmailVerification(user, actionCodeSettings)
            .then(async() => {
                SetOpenInfo(true);
                await addDoc(collection(db, "BusinessProfile"), {
                    uID: user.uid,
                    name: name,
                    phoneNumber: phoneNumber,
                    email: email,
                    
                });
            })
            .catch((error) => {
                
            });

            /*no need for const user = userCredential.user as we are using onAuthStateChanged in firebaseInit.js
            hence by using createUserwithEmailAndPassword it makes onAuthStateChagned get hit and update the user */
        })
        .catch((error) => {
            //failed signup
            const errorCode = error.code;
            const errorMessage = error.message;
            switch(errorCode) {
                case 'auth/invalid-email':
                    emailErrorElement.current.innerHTML = "Invalid email"
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
                case 'auth/weak-password':
                    passErrorElement.current.innerHTML = "Weak password"
                    passRef.current.style.border = "1px solid red"
                    passErrorElement.current.style.display = "block";
                    break
                case 'auth/email-already-in-use':
                    emailErrorElement.current.innerHTML = "Email already in use"
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
                default:
                    emailErrorElement.current.innerHTML = {errorMessage}
                    emailRef.current.style.border = "1px solid red"
                    emailErrorElement.current.style.display = "block";
                    break
            }
        });
    }

    return (
        <div>
            <div className="login-container">
                <h1 id="login-title-text">Business Signup</h1>
                <p className="welcome-message">Ready to gain more traffic? Join today!</p>
                <form onSubmit={signupSubmit} className="login-form">
                    <div>
                        <input autoComplete="off" id="name" ref={nameRef} type="text" placeholder="Full Name" onChange={handleNameChange} required />
                    </div>
                    <div>
                        <InputMask
                            mask="(999)-999-9999"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                        >
                            {() =><input autoComplete="off" id="phone-number" ref={phoneNumberRef} type="text" placeholder="Phone Number" required />
                        }
                        </InputMask>
                    </div>
                    <div id="email-and-emailerror">
                        <input autoComplete="off" id="email" ref={emailRef} type="text" placeholder="Email" onChange={handleEmailChange} required />
                        <div ref={emailErrorElement} className="error-message"></div>
                    </div>
                    <div id="pass-and-passerror">
                        <input autoComplete="off" id="password" ref={passRef} type="password" placeholder="Password" onChange={handlePassChange} required />
                        <div ref={passErrorElement} className="error-message"></div>
                    </div>

                    <div>
                        <input autoComplete="off" id="retype-password" ref={retypePassRef} type="password" placeholder="Retype Password" onChange={handlePasswordRetype} required />
                        <div ref={retypeErrorElement} className="error-message"></div>
                    </div>
                    <Collapse className="alert-container" in={alertInfo}>
                        <Alert className="alert" variant="outlined" severity="info" onClose={() => {SetOpenInfo(false)}}>Please Head to your email to verify your sign up!</Alert>
                    </Collapse>
                    <button id="business-submit" type="submit">Submit</button>
                    
                </form>
            </div>
           
        </div>
    )
}

export default BusinessStartSignup;