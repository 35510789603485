import './App.css';
//simply importing firebase.js will run it before start of program
import "./firebaseInit";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useLocation } from "react-router-dom";
import Home from "./component/Home";
import AboutUs from "./component/AboutUs";
import Signup from './component/Signup';
import Login from './component/Login';
import StudentStartSignup from './component/StudentSignup';
import BusinessStartSignup from './component/BusinessSignup';
import BusinessProfileSetup from './component/BusinessProfile';
import ExplorePage from "./component/ExplorePage"
import Applications from "./component/Applications"
import Apply from "./component/Apply"
import StudentProfile from "./component/StudentProfile"
import ApplyForm from "./component/ApplyForm"
import logoLight from "./photos/logo-light.png"
import logoDark from "./photos/logo-dark.png"
import MyApplications from './component/MyApplications';
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './component/Footer';
import { useEffect, useState, useRef } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import CheckStudentAuthenticated from './component/ProtectedRoutes/CheckStudentAuthenticated';
import CheckBusinessAuthenticated from './component/ProtectedRoutes/CheckBusinessAuthenticated';
import { db, user, auth } from './firebaseInit';
import NotFound from './component/NotFound';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Emails from './component/Emails';
import Verification from './component/Verification';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Admin from './component/Admin';
import CheckAdminAuthenticated from './component/ProtectedRoutes/CheckAdminAuthenticated';
import ForgotPassword from './component/ForgotPassword';


function App() {
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    //these are used for conditionalally rendering the nav bar
    const [isStudent, setIsStudent] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        //must be in nested func because of async
        const checkIfStudent = async() => {
            //if user is authed, check if they are a student
            //CAREFUL: In the future, we may want to check if the user is a business as well to avoid hard coding, this assumes only two types of users (student and business)
            if(user) {
                const querySnapshot = await getDocs(collection(db, "studentProfile"), where("uID", "==", user.uid), limit(1));
                if(querySnapshot.docs.length == 1) {
                    setIsStudent(true)
                    setIsBusiness(false)
                } else {
                    setIsStudent(false)
                    setIsBusiness(true)
                }
            }
        }
        checkIfStudent();
    }, [])

    useEffect(() => {
        if(user) {
            if(user.uid == "PnBA2xmGH5VrazR8JAF28XsGu2H2") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        } else {
            setIsAdmin(false)
        }
    }, [user])

    //logic: user is authed, then immediately sends uid to firebase upon auth, then this queries the firebase db to see if the user is a student or business
    const [currentAccount, setCurrentAccount] = useState("Not signed up yet");
    const changeTextDescribingUserType = async(newUser) => {
        // structure of the code due to readings from firestore (cost per read)
        if(newUser){
            console.log("new user: ", newUser.uid);
            const studentQuerySnapshot = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", newUser.uid), limit(1)));

            if(studentQuerySnapshot.docs.length === 1 && newUser.emailVerified === true) {
                console.log("student account found")
                setCurrentAccount("Student")
                setIsStudent(true)
                setIsBusiness(false)
            } else {
                const businessQuerySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", newUser.uid), limit(1)));

                if(businessQuerySnapshot.docs.length === 1 && newUser.emailVerified === true) {
                    setCurrentAccount("Business");
                    setIsStudent(false)
                    setIsBusiness(true)

                } else {
                    console.log("should not reach here - test account not verified");
                }
            }
        } else {
            setCurrentAccount("No account type found")
            setIsStudent(false)
            setIsBusiness(false)
        }
    }

    useEffect(() => {
        //this hits when the auth is changed, but user info hasn't been put into firestore yet so checking uid will always reuls in not being there
        onAuthStateChanged(auth, changeTextDescribingUserType);
    }, [])

    const signout = async() => {
        signOut(auth).then(() => {
            console.log("signed out")
            setCurrentAccount("No account type found")
            setIsBusiness(false)
            setIsStudent(false)
            navigate('/')
        }).catch((error) => {
            console.log("error: ", error)
        });
    }

    // attempt menuClose when user not focued on menu.
    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
        // Hide the app-menu-bar when the sidebar is open
        if (menuOpen) {
            document.getElementsByClassName('App-menu-bar')[0].style.display = 'flex';
        } else {
            document.getElementsByClassName('App-menu-bar')[0].style.display = 'none';
        }
    };

    const handleClickOutside = () => {
        setMenuOpen(false);
        document.getElementsByClassName('App-menu-bar')[0].style.display = 'flex';
    }

    const location = useLocation()
    const isActive = (path) => {
        return location.pathname + location.hash === path;
    }

    return ( 
        <div>
            <ThemeProvider theme={theme}>
            <CssBaseline />
                <div className="App">
                    <ul className="App-menu-bar">
                    
                    {/* "Link" prevents "active" className from popping up.
                    Having NavLink caused logo to underline on homepage */}
                    
                    <li id="first">
                        <Link to="/#home">
                            {theme.palette.mode === 'dark' ? (
                                <img src={logoLight} alt="logo" id="logo"/>
                            ) : (
                                <img src={logoDark} alt="logo" id="logo"/>
                            )}
                        </Link>
                    </li>

                    {!isStudent && !isBusiness &&
                        <li className='hideOnThin'>
                            {/* NavLink implicitly has a class name of 
                            activeClassName which = active when pressed */}
                            <NavLink to="/#home" end className={() => (isActive('/#home') ? 'button active' : 'button')}>
                                Home
                            </NavLink>
                        </li>
                    }

                    {!isStudent && !isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/about" className='button'>
                                About Us
                            </NavLink>
                        </li>
                    }

                    {!isStudent && !isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/#benefitSection" className={() => (isActive('/#benefitSection') ? 'button active' : 'button')}>
                                Benefits
                            </NavLink>
                        </li>
                    }

                    {!isStudent && !isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/#featureSection" className={() => (isActive('/#featureSection') ? 'button active' : 'button')}>
                                Features
                            </NavLink>
                        </li>
                    }
                    
                    {!isStudent && !isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/#faq" className={() => (isActive('/#faq') ? 'button active' : 'button')}>
                                FAQ
                            </NavLink>
                        </li>
                    }

                    {/* render only if user is a student */}
                    { isStudent &&
                        <li className='hideOnThin'>
                            <NavLink to="/explore-page" className='button'>
                                Explore
                            </NavLink>

                        </li>
                    }

                    {/* render only if user is admin */}
                    { isAdmin &&
                        <li>
                            <NavLink to="/admin" className='button'>
                                admin
                            </NavLink>
                        </li>
                    }

                    {/* render only if user is a student */}
                    { isStudent &&
                        <li className='hideOnThin'>

                            <NavLink to="/applications" className='button'>
                                My Applications

                            </NavLink>
                        </li>
                    }
                    
                    {/* render only if user is a business */}
                    { isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/my-applications" className='button'>
                                Job Applications
                            </NavLink>
                        </li>
                    }

                    {/* render only if user is a business */}
                    { isBusiness &&
                        <li className='hideOnThin'>
                            <NavLink to="/business-profile" className='button'>
                                My Profile
                            </NavLink>
                        </li>
                    }

                    {/* render only if user is a student */}
                    { isStudent &&
                        <li className='hideOnThin'>
                            <NavLink to="/student-profile" className='button'>
                                My Profile
                            </NavLink>
                        </li>
                    }

                    {/* render only if neither user is signed in */}
                    { !isStudent && !isBusiness &&
                        <li className="container hideOnThin">
                            <Link to="/login" className='button login-button'>
                                Login
                            </Link>
                        </li>
                    }

                    {/* render only if neither user is signed in */}
                    { !isStudent && !isBusiness &&
                        <li className="container hideOnThin">
                            <Link to="/signup" className='button login-button'>
                                Sign Up
                            </Link>
                        </li>
                    }

                    {/* render only if signed in */}
                    { (isStudent || isBusiness) &&
                        <li className="container hideOnThin">
                            <button onClick={signout} id='signout' >
                                Sign Out
                            </button>
                        </li>
                    }

                    
                    <div className="menu-icon hideOnWide" >
                        <MenuIcon className="sidebarOpen" onClick={handleMenuToggle}/>
                    </div>
                    </ul>
                
                    {menuOpen && (
                        <ul className='hideOnWide' id="sidebar">
                            {/* "Link" prevents "active" className from popping up.
                            Having NavLink caused logo to underline on homepage */}
                            <div className="burger-icons">
                                { (!isStudent && !isBusiness) &&
                                    <Link to="/#home">
                                        {theme.palette.mode === 'dark' ? (
                                            <img src={logoLight} alt="logo" id="logo"/>
                                        ) : (
                                            <img src={logoDark} alt="logo" id="logo"/>
                                        )}
                                    </Link>
                                }
                                <CloseIcon className="sidebar-close" onClick={handleMenuToggle}/>
                            </div>

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    {/* NavLink implicitly has a class name of
                                    activeClassName which = active when pressed */}

                                    <NavLink to="/#home" end onClick={handleMenuToggle} className={() => (isActive('/#home') ? 'button active' : 'button')}>
                                        Home
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/about" className='button' onClick={handleMenuToggle}>
                                        About Us
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#benefitSection" className={() => (isActive('/#benefitSection') ? 'button active' : 'button')}>
                                        Benefits
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#featureSection" className={() => (isActive('/#featureSection') ? 'button active' : 'button')}>
                                        Features
                                    </NavLink>
                                </li>
                            }
                            
                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#faq" className={() => (isActive('/#faq') ? 'button active' : 'button')}>
                                        FAQ
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/explore-page" className='button' onClick={handleMenuToggle}>
                                        Explore
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/applications" className='button' onClick={handleMenuToggle}>
                                        My Applications
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/student-profile" className='button' onClick={handleMenuToggle}>
                                        My Profile
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a business */}
                            { isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/my-applications" className='button' onClick={handleMenuToggle}>
                                        Job Applications
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a business */}
                            { isBusiness &&
                                <li className='hideOnWide' >
                                    <NavLink to="/business-profile" className='button' onClick={handleMenuToggle}>
                                        My Profile
                                    </NavLink>
                                </li>
                            }

                            {/* render only if neither user is signed in */}
                            { !isStudent && !isBusiness &&
                                <li className="container hideOnWide">
                                    <Link to="/login" className='button login-button' onClick={handleMenuToggle}>
                                        Login
                                    </Link>
                                </li>
                            }

                            {/* render only if neither user is signed in */}
                            { !isStudent && !isBusiness &&
                                <li className="container hideOnWide ">
                                    <Link to="/signup" className='button login-button' onClick={handleMenuToggle}>
                                        Sign Up
                                    </Link>
                                </li>
                            }

                            {/* render only if signed in */}
                            { (isStudent || isBusiness) &&
                                <li className="container hideOnWide">
                                    <button onClick={signout} id='signout'>
                                        Sign Out
                                    </button>
                                </li>
                            }

                            

                        </ul>
                    )}


                    <div className="app-body" onClick={handleClickOutside}>
                        <Routes>
                            <Route
                                path="/"
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/about"
                                element={<AboutUs />}
                            ></Route>

                            <Route
                                path="/login"
                                element={<Login />}
                            ></Route>
                            <Route
                                path="/signup"
                                element={<Signup />}
                            ></Route>
                            <Route
                                path="/student-signup"
                                element={<StudentStartSignup/>}
                            ></Route>
                            <Route
                                path="/business-signup"
                                element={<BusinessStartSignup/>}
                            ></Route>
                            <Route
                                path="/business-profile"
                                element={<BusinessProfileSetup/>}
                            ></Route>
                            {/* explore page for students */}
                            <Route
                                path="/explore-page"
                                element={<CheckStudentAuthenticated ComponentWithAuth={ExplorePage}/>}
                            ></Route>
                            <Route
                                path="/applications"
                                element={<CheckStudentAuthenticated ComponentWithAuth={Applications}/>}
                            ></Route>
                            {/* : indicates dynamic segment, useParams()
                            returns an object of the actual data put after /apply/*/}
                            <Route
                                path="/apply/:busID"
                                element={<Apply/>}
                            ></Route>
                            <Route
                                path="/student-profile"
                                element={<StudentProfile/>}
                            ></Route>
                            <Route
                                path="/apply-form/:busID"
                                element={<ApplyForm/>}
                            ></Route>

                            <Route
                                path="/my-applications"
                                element={<CheckBusinessAuthenticated ComponentWithAuth={MyApplications}/>}
                            ></Route>
                            <Route
                                path="/emails"
                                element={<Emails/>}
                            ></Route>
                            <Route
                                path="*"
                                element={<NotFound/>}
                            ></Route>
                            <Route
                                path="/verification"
                                element={<Verification/>}
                            ></Route>
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword/>}
                            ></Route>
                            <Route
                                path="/admin"
                                element={<CheckAdminAuthenticated ComponentWithAuth={Admin}/>}
                            ></Route>
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        </div>
    );
}

export default App;
