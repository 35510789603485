//this is for students looking to see the apps they sent out
import React, { useEffect, useState } from "react";
import { query } from "firebase/firestore";
import { collection, where, getDocs } from "firebase/firestore";
import ApplicationProfileCard from "./StudentProfileCard";
import Loading from "./Loading";
import { user, db } from "../firebaseInit";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import "../Styling/Applications.css"
import "../Styling/BusinessProfileCard.css"

//imports for the table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const Applications = () => {
    //doc data is array of objects... the objects are the data of the busineses
    const[docData, setDocData] = useState([]);
    const[acceptedOrRejected, setAcceptedOrRejected] = useState([]);
    const[loading, setLoading] = useState(true);
    const[noApps, setNoApps] = useState(false); 
    
    useEffect(() => {
        const verifyAuthAndGetApps = async() => {
            const querySnapshotStudent = await getDocs(query(collection(db, "applications"), where("from", "==", user.uid)));
            if(querySnapshotStudent.empty){
                setNoApps(true)
            } else {
                setNoApps(false)
            }
            //THE ORDER IS IMPORTANT HERE, THE DOC DATA ARRAY MUST BE IN THE SAME ORDER AS THE ACCEPTED OR REJECTED ARRAY
            const toIds = querySnapshotStudent.docs.map(doc => doc.data().to)
            setAcceptedOrRejected(querySnapshotStudent.docs.map(doc => doc.data().status))
            const docDataStandIn = []
            await Promise.all(
                toIds.map(async(toId) => {
                const docSnap = await getDoc(doc(db, "BusinessProfile", toId));
                docDataStandIn.push(docSnap.data())
                })
            )
            setDocData(docDataStandIn);
            setLoading(false)  
        }
        verifyAuthAndGetApps();
    }, [])
    

    return ( 
        <div>
            <h1>My Applications</h1>
            <div id="myapplications-page-body">
            { loading ? (
                <Loading/>
                ) : noApps ? (
                    <div>
                        <h1>You have not sent out any apps yet</h1>
                        <h4>Go to <Link to="/explore-page">explore page</Link> to apply</h4>

                   </div>
                ) : (
                    <div id="table-container">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Pay</TableCell>
                                        <TableCell align="center">Company Name</TableCell>
                                        <TableCell align="center">Company Description</TableCell>
                                        <TableCell align="center">Company Location</TableCell>
                                        <TableCell align="center">Accepted/Rejected</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {docData.map((doc, index) => (
                                        <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">${doc.price}</TableCell>   
                                            <TableCell align="center">{doc.companyName}</TableCell>
                                            <TableCell align="center">{doc.companyDescription}</TableCell>
                                            <TableCell align="center">{doc.companyLocation}</TableCell>
                                            <TableCell align="center">
                                                <div className="statusBlock">
                                                    {acceptedOrRejected[index]}
                                                    <Tooltip 
                                                        title={acceptedOrRejected[index] === "pending" ? (
                                                            "This application is still pending"
                                                        ) : (
                                                            acceptedOrRejected[index] === "accepted" ? (
                                                                "You have been accepted"
                                                            ) : (
                                                                "You have been rejected"
                                                            )
                                                        )}
                                                        placement="right"
                                                        arrow
                                                    >
                                                        <InfoIcon className="info-icon"/>

                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
            }
            </div>
        </div>
    ) 
}

export default Applications