import { db } from '../firebaseInit';
import { useState, useEffect } from 'react';
import { getDocs, query, collection, where } from 'firebase/firestore';
import BusinessProfileCard from './BusinessProfileCard';
import { updateDoc } from 'firebase/firestore';
import { getDoc, doc } from 'firebase/firestore';


import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Admin = () => {
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusValue, setStatusValue] = useState([]);
    const [successfulStudentAndBusinessConnects, setSuccessfulStudentAndBusinessConnects] = useState({});

    useEffect(() => {
        const asyncWrapper = async () => {
            //this is to get the emails for the sucessful student/business connects
            const successfulApps = await getDocs(query(collection(db, "applications"), where("status", "==", "accepted")));
            
            const arrayOfBusEmails = [];
            const arrayOfStudEmails = [];
            for(const sucessfulApp of successfulApps.docs)  {
                //I SET THIS UP SO BAD RIPPP
                const refToBusiness = await getDoc(doc(db, "BusinessProfile", sucessfulApp.data().to))
                const refToStudent = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", sucessfulApp.data().from)));
                
                arrayOfBusEmails.push(refToBusiness.data().email);
                arrayOfStudEmails.push(refToStudent.docs[0].data().email);
            }
           
            let tempDictForEmails = {};
            for(let i = 0; i < arrayOfBusEmails.length; ++i) {
                tempDictForEmails[arrayOfStudEmails[i]] = arrayOfBusEmails[i];
            }
            console.log(tempDictForEmails)
            
            setSuccessfulStudentAndBusinessConnects(tempDictForEmails);


            //this is to get the value for the status value and the docs
            //put price != "" to filter out businesses that have not clicked submit for their profile creation yet (price is a required field)
            const q = await getDocs(query(collection(db, "BusinessProfile"), where("status", "==", "pending"), where("price", "!=", "")));
            if(q.docs.length !== 0) {
                let statuses = [];

                setDocs(q.docs.map((doc) => {
                    statuses.push(doc.data().status == "pending" ? 1 : doc.data().status == "accepted" ? 2 : 3);
                    return { id: doc.id, data: doc.data() }
                }
                )); // Include id and data
                setStatusValue(statuses);
                
            } 
            setLoading(false); 
        };
        asyncWrapper();
    }, []);

    const handleChange = async(event, index) => {    
        setStatusValue((prev) => {
            const newStatusValue = [...prev];
            newStatusValue[index] = event.target.value;
            return newStatusValue;
        })

        const q = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", docs[index].data.uID)));
        if(q.docs.length === 0) {

        } else {
            const doc = q.docs[0];
            const docRef = doc.ref;
            event.target.value === 1 ? await updateDoc(docRef, {
                status: "pending",
            }) : event.target.value === 2 ? await updateDoc(docRef, {
                status: "accepted",
            }) : await updateDoc(docRef, {
                status: "rejected",
            })  
            
        }
    }

    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <table>
                        <thead>
                            <tr >
                                <th>Business Name</th>
                                <th>Location</th>
                                <th>Business Description</th>
                                <th>Price</th>
                                <th>Approve/Deny</th>
                                <th>business ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {docs.map((doc, index) => (
                                <tr key={index}>
                                    <td>{doc.data.companyName}</td>
                                    <td>{doc.data.companyLocation}</td>
                                    <td>{doc.data.companyDescription}</td>
                                    <td>${doc.data.price}</td>
                                    <td>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Age"
                                                value={statusValue[index]}
    
                                                onChange={(event) => handleChange(event, index)}
                                                >
                                                <MenuItem value={1}>Pending</MenuItem>
                                                <MenuItem value={2}>Accepted</MenuItem>
                                                <MenuItem value={3}>Rejected</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </td>
                                    <td>{doc.id}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <hr></hr>
                    <h1>here are the sucessful acceptances (business accepted the student)</h1>
                    <table>
                        <thead>
                            <tr >
                                <th>Business Email</th>
                                <th>Student Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.entries(successfulStudentAndBusinessConnects).map(([studentEmail, businessEmail], index) => (
                                    <tr key={index}>
                                        <td>{businessEmail}</td>
                                        <td>{studentEmail}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Admin;