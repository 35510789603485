import { db } from "../firebaseInit";
import { useEffect } from "react";
import { getDocs, query, collection, where, getDoc, doc } from "firebase/firestore";
import { useState } from "react";

const Emails = () => {
    const [loading, setLoading] = useState(true);
    //list of objects representing emails of students/businesses that have connected (been accepted)
    const [emailsAndPrices, setEmailsAndPrices] = useState([]);
    
    useEffect(() => {
        const wrapper = async() => {
            const querySnapshotApplication = await getDocs(query(collection(db, "applications"), where("status", "==", "accepted")));
            const placeholder = [];
            const promises = await Promise.all(querySnapshotApplication.docs.map(async(appDoc) => {
                const from = appDoc.data().from;
                const to = appDoc.data().to;
                const student = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", from)));
                const docSnap = await getDoc(doc(db, "BusinessProfile", to));
                placeholder.push({ studentEmail: student.docs[0].data().email, businessEmail: docSnap.data().email, price: docSnap.data().price });
            }))
            setEmailsAndPrices(placeholder);
            setLoading(false);
        }
        wrapper();
    }, [])
    
    return (
        loading ? (
            <div>Loading...</div> 
        ) : (
            <div>
                <h1>Emails</h1>
                {
                    emailsAndPrices.map((stuff) => (
                        <div>
                            <h1>Successful connect (bus and student accept): </h1>
                            <p>Student: {stuff.studentEmail}, Business: {stuff.businessEmail}, Price: {stuff.price}</p>
                        </div>
                    ))
                    
                }
            </div>
        )
        
        
    )
}

export default Emails;